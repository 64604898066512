import { type ReviewContent } from '~/services/community';
import { REVIEW_TYPE, type ReviewType } from '~/services/product/constant';

import {
  type AlarmDisplaySeq,
  type ClassByCategoryListOptions,
  type DesignDisplayCategory,
  type DesignModule,
  type DesignModuleSlide,
  DesignModuleType,
  type DesignPopup,
  type MenuType,
  type PaymentButtonCta,
  type Product,
  type ProductWithPaging,
  type ReviewCountType,
  type WeolbuStudyDesignModule,
  mainService,
  reviewService,
} from '.';
import Utility from '../Utility';
import { type PaymentInfo } from '../payment';

interface State {
  headerSticky: boolean;
  priceSticky: boolean;
  designModuleMainBannerList: DesignModule[];
  designModuleMainSlideList: DesignModule[];
  designModuleMainProductList: DesignModule[];
  designModuleMainWeolbuStudyList: WeolbuStudyDesignModule[];
  designModuleMainList: { items: DesignModule[] };
  recentProductList: { items: Product[] };
  selectedCategoryBanner: DesignDisplayCategory;
  selectedClassByCategoryList: ProductWithPaging;
  selectedCurationList: ProductWithPaging;
  designModuleSlideList: DesignModuleSlide[];
  selectedDesignModuleSideMenu: DesignModule;
  isOpenDesignModule: boolean;

  isOpenDesignDisplayCategory: boolean;

  selectedDesignDisplayCategory: DesignDisplayCategory;
  designDisplayCategoryList: DesignDisplayCategory[];
  slideBannerDialog: { visible: boolean; detailData: DesignModuleSlide };
  selectedProduct: Product;
  designPopupList: DesignPopup[];
  paymentButtonCtaOption: PaymentButtonCta;
  visibleTopBanner: boolean;
  isLoading_fetchClassByCategoryList: boolean;
  alarmDisplay: AlarmDisplaySeq;
  isMainPopupOpened: boolean;
  mainPopupQueue: DesignPopup[];
  gnbMenuList: MenuType[];
  quickMenuList: MenuType[];
  completePurchasePopup: boolean;
  signupEventPopup: boolean;
  signupPopupType: 'DIALOG' | 'BANNER';
  signupBannerClicked: boolean;

  videoPreviewPopupContent: {
    title: string;
    description: string;
    videoUrl: string;
  };
  showVideoPreviewPopup: boolean;

  mobileBestReviewPopup: boolean;
  selectedBestReview: ReviewContent | null;
  bestReviewList: ReviewContent[];
  mobileLectureAchievementPopup: boolean;
  selectedLectureAchievementIndex: number | null;

  reviewCount: ReviewCountType;
}

export const useMainStore = defineStore('main-store', {
  state: (): State => ({
    designModuleMainProductList: [],
    designModuleMainBannerList: [],
    designModuleMainSlideList: [],
    designModuleMainWeolbuStudyList: [],
    designModuleMainList: { items: [] },
    recentProductList: { items: [] },
    designModuleSubList: {
      items: [],
    },
    selectedProduct: {} as Product, //=> Review 제거
    selectedDesignModuleSideMenu: {} as DesignModule,

    selectedDesignDisplayCategory: {} as DesignDisplayCategory,
    isOpenDesignDisplayCategory: false,
    designDisplayCategoryList: [] as DesignDisplayCategory[],
    designModuleSlideList: [] as DesignModuleSlide[],
    // @ts-ignore
    slideBannerDialog: { visible: false, detailData: {} },
    selectedClassByCategoryList: {} as ProductWithPaging,
    selectedCurationList: {} as ProductWithPaging,
    selectedCategoryBanner: {} as DesignDisplayCategory,
    designPopupList: [],
    paymentButtonCtaOption: {} as PaymentButtonCta,
    visibleTopBanner: false,
    headerSticky: false,
    priceSticky: false,
    isLoading_fetchClassByCategoryList: true,
    alarmDisplay: {} as AlarmDisplaySeq,
    isMainPopupOpened: false,
    mainPopupQueue: [] as DesignPopup[],
    gnbMenuList: [] as MenuType[],
    quickMenuList: [],
    completePurchasePopup: false,
    signupEventPopup: false,
    signupPopupType: 'DIALOG',
    signupBannerClicked: false,

    videoPreviewPopupContent: {
      title: '',
      description: '',
      videoUrl: '',
    },
    showVideoPreviewPopup: false,

    mobileBestReviewPopup: false,
    selectedBestReview: null,

    mobileLectureAchievementPopup: false,
    selectedLectureAchievementIndex: null,
    reviewCount: {} as ReviewCountType, // @Todo 제거
  }),
  persist: false,
  actions: {
    async fetchClassByCategoryList(cateSeq: number | string) {
      const isSeqChanged = this.selectedClassByCategoryList.cateSeq != cateSeq;
      const initialPageSize = 12;

      let isDataReseted = false;
      const resetData = () => {
        // paging 이 없음.
        // this.selectedClassByCategoryList.items = [];
        isDataReseted = true;
        // @ts-ignore
        this.selectedClassByCategoryList.paging = {
          pageNo: 1,
          pageSize: initialPageSize,
          orderColumn: 'RECENT_COUNT', // 인기순이 기본.
          orderType: 'DESC',
        };
      };

      if ($isEmpty(this.selectedClassByCategoryList.paging)) {
        // 최초 패이지 로드.
        resetData();
      } else if (this.selectedClassByCategoryList.cateSeq == cateSeq) {
        if (
          this.selectedClassByCategoryList.paging.orderColumnOld &&
          this.selectedClassByCategoryList.paging.orderColumnOld != this.selectedClassByCategoryList.paging.orderColumn
        ) {
          // column order 만 바꾼거라면
          this.selectedClassByCategoryList.paging.pageNo = 1;
          this.selectedClassByCategoryList.paging.pageSize = initialPageSize;
          isDataReseted = true;
        } else {
          // 과거 검색과 같은 cateSeq 라면 paging 을 늘려줌.
          this.selectedClassByCategoryList.paging.pageNo = this.selectedClassByCategoryList.paging.pageNo + 1;
        }
      } else {
        // 그렇지안다면 reset
        resetData();
      }

      this.selectedClassByCategoryList.cateSeq = cateSeq;
      const params = {
        ...this.selectedClassByCategoryList.paging,
        cateSeq,
      } as ClassByCategoryListOptions;

      if (params.pageNo > 1) {
        // params.pageSize = 6; // 더보기는 6개씩만 가져옴.
        params.pageSize = initialPageSize;
      }
      const orderColumnOld = this.selectedClassByCategoryList.paging.orderColumn; // order column 을 기억해둠.

      this.isLoading_fetchClassByCategoryList = true;

      const extraParams = {};
      if (params.cateSeq === 'earlybirdSpecialPrice') {
        extraParams.remainingEarlyBirdQuantity = 1000;
      } else if (params.cateSeq === 'newClass') {
        extraParams.productCategory = 'ORIGINAL,CLASS';
      }

      try {
        const res = await mainService.fetchClassByCategoryList(params, extraParams);
        if (isDataReseted == true) {
          this.selectedClassByCategoryList.items = [];
        }
        if ($isEmpty(this.selectedClassByCategoryList.items)) {
          this.selectedClassByCategoryList = res.data;
        } else {
          this.selectedClassByCategoryList.paging = res.data.paging;
          this.selectedClassByCategoryList.items = this.selectedClassByCategoryList.items.concat(res.data.items);
        }
        this.selectedClassByCategoryList.cateSeq = cateSeq;
        this.selectedClassByCategoryList.paging.orderColumnOld = orderColumnOld;
      } finally {
        this.isLoading_fetchClassByCategoryList = false;
      }
    },

    async fetchCurationList(cateSeq: number) {
      let pageNo = 1;
      if (!$isEmpty(this.selectedCurationList)) {
        if (this.selectedCurationList.cateSeq != cateSeq) {
          pageNo = 1;
        } else {
          pageNo = this.selectedCurationList.paging.pageNo + 1;
        }
      }
      const params = {
        cateSeq,
        pageSize: 999,
        pageNo,
      } as any;

      const res = await mainService.fetchCurationList(params);
      if ($isEmpty(this.selectedCurationList == null)) {
        this.selectedCurationList = res.data.data;
      } else {
        this.selectedCurationList.paging = res.data.data.paging;
        this.selectedCurationList.items = this.selectedCurationList.items.concat(res.data.data.items);
      }
    },
    async fetchCategoryBanner(cateSeq: number) {
      const res = await mainService.fetchCategoryBanner(cateSeq);
      this.selectedCategoryBanner = res.data.data.items;
    },
    async fetchDesignModuleList() {
      const res = await mainService.fetchDesignModuleList();
      const designModuleList = res.data;
      const filteredProductList = designModuleList.items.filter((v) => v.designModuleType === DesignModuleType.PRODUCT);
      const filteredSlideList = designModuleList.items.filter((v) => v.designModuleType === DesignModuleType.SLIDE);
      const filteredBannerList = designModuleList.items.filter((v) => v.designModuleType === DesignModuleType.BANNER);
      const filteredWeolbuStudyList = designModuleList.items.filter(
        (v) => v.designModuleType === DesignModuleType.WEOLBU_STUDY,
      );
      this.designModuleMainProductList = filteredProductList;
      this.designModuleMainSlideList = filteredSlideList;
      this.designModuleMainBannerList = filteredBannerList;
      this.designModuleMainWeolbuStudyList = filteredWeolbuStudyList;
      this.designModuleMainList = res.data;
      return res;
    },
    async fetchRecentDesignModuleList() {
      const res = await mainService.fetchRecentModuleList();
      this.recentProductList = res.data.data;
    },
    async fetchClassDetail(displaySeq: number, abTestGroup: 'A' | 'B' = 'A') {
      const [displayDetailRes, couponListRes, productOptionRes, priceRes] = await Promise.all([
        mainService.fetchClassDetail(displaySeq, abTestGroup),
        mainService.fetchAvailableCouponOfMine(displaySeq),
        mainService.fetchProductOptions(displaySeq),
        mainService.fetchClassPrice(displaySeq),
      ]);
      if (!displayDetailRes || !displayDetailRes?.data.items) {
        throw new Error('클래스가 존재하지 않습니다.');
      }
      if (displayDetailRes?.status.errorCode) {
        throw new Error(displayDetailRes?.status.errorMessage);
      }

      this.selectedProduct = {
        ...displayDetailRes?.data?.items,
        availableCouponOfMine: couponListRes?.data?.data?.items,
        productOption: productOptionRes?.data?.data?.items,
        classPrice: priceRes?.data?.data?.items,
      };
    },
    async fetchPaymentClassDetail({ displaySeq, optionSeq = '', referralCode }: PaymentInfo) {
      const [paymentDetailRes, priceRes, couponRes, pointRes, giftCardRes] = await Promise.all([
        mainService.fetchPaymentClassDetail(displaySeq, optionSeq, referralCode),
        mainService.fetchClassPrice(displaySeq, referralCode),
        mainService.fetchAvailableCouponOfMine(displaySeq, referralCode),
        mainService.fetchAvailablePoint(),
        mainService.fetchGiftCardsMyTotalBalance(),
      ]);
      const productOptionRes =
        optionSeq && optionSeq !== '' ? await mainService.fetchProductOption(displaySeq, optionSeq) : undefined;

      if (paymentDetailRes?.data?.status.errorCode) {
        throw new Error(paymentDetailRes?.data?.status.errorMessage);
      }

      this.selectedProduct = {
        ...paymentDetailRes.data.data.items,
        classPrice: priceRes.data.data.items,
        productOption: productOptionRes?.data.data.items,
        availableCouponOfMine: couponRes.data.data.items,
        availablePoint: pointRes.data.data.items,
        giftCardTotalBalance: giftCardRes.totalAvailableBalance,
      };
    },
    async fetchPaymentCaptcha(displaySeq: number) {
      const res = await mainService.fetchPaymentCaptcha(displaySeq);
      this.selectedProduct.captcha = res.data.data.items;
    },

    async fetchPaymentButtonCta(displaySeq: number) {
      const res = await mainService.fetchPaymentButtonCta(displaySeq);
      if (!res?.data) {
        return;
      }

      this.paymentButtonCtaOption = res.data.data.items;
    },
    async fetchPaymentWaitCheck(displaySeq: number, optionSeq: number) {
      const res = await mainService.fetchPaymentWaitCheck(displaySeq, optionSeq);
      this.selectedProduct.paymentWait = res.data.data.items.paymentWait;
      return res.data.data.items.paymentWait;
    },
    async addPaymentWait(displaySeq: number, optionSeq?: number | null) {
      const res = await mainService.addPaymentWait(displaySeq, optionSeq);
      return res;
    },
    async deletePaymentWait(displaySeq: number, optionSeq: number | null) {
      const res = await mainService.deletePaymentWait(displaySeq, optionSeq);
      return res;
    },
    async addPaymentAlarm(displaySeq: number) {
      const res = await mainService.addPaymentAlarm(displaySeq);
      return res;
    },
    async deletePaymentAlarm(displaySeq: number) {
      const res = await mainService.deletePaymentAlarm(displaySeq);
      return res;
    },
    /* 	async fetchAvailableCoupon(displaySeq: number) {
      let res = await mainService.fetchAvailableCoupon(displaySeq);
      this.selectedProduct.availableCoupon = res.data.data.items;
    }, */
    async fetchClassPrice(displaySeq: number) {
      const res = await mainService.fetchClassPrice(displaySeq);
      this.selectedProduct.classPrice = res.data.data.items;
    },
    async fetchAvailableCouponOfMine(displaySeq: number) {
      const res = await mainService.fetchAvailableCouponOfMine(displaySeq);
      this.selectedProduct.availableCouponOfMine = res.data.data.items;
    },
    async fetchAvailablePoint() {
      const res = await mainService.fetchAvailablePoint();
      this.selectedProduct.availablePoint = res.data.data.items;
    },
    async fetchProductOptions(displaySeq: number) {
      const res = await mainService.fetchProductOptions(displaySeq);
      this.selectedProduct.productOption = res.data.data.items;
    },
    async fetchProductOption(displaySeq: number, optionSeq: number) {
      const res = await mainService.fetchProductOption(displaySeq, optionSeq);
      this.selectedProduct.productOption = res.data.data.items;
    },
    async fetchDesignPopup() {
      const res = await mainService.fetchDesignPopup(null);
      this.designPopupList = res.data.data.items
        .filter((item) => {
          return Utility.hasCachedInOneDay(item.designPopupSeq, 'designPopupSeq') == false;
          // return false;
        })
        .map((item) => ({
          ...item,
          ui_visible: true,
          ui_is_open_oneday: false,
        }));
    },
    async fetchReviewList(productSeq: number, page: number, reviewOptions: { orderType: ReviewType }) {
      const { orderType = REVIEW_TYPE.RECOMMENDATION } = reviewOptions || {};
      const pageSize = 5;
      // 상품기준으로 상품후기를 보여줘야해서 productSeq로 조회하도록 수정(6.16)
      const params = {
        productSeq,
        pageSize,
        page,
        orderType,
      } as any;

      const res = await reviewService.fetchProductReviews(params);
      if (!res?.data) {
        return;
      }
      this.selectedProduct.reviewList = res.data;
    },
    async fetchBestReviewList(
      productSeq: number,
      { displaySeq, abTestGroup }: { displaySeq?: number; abTestGroup?: 'A' | 'B' | 'C' } = {},
    ) {
      const params = {
        searchBestYn: 'Y',
        productSeq,
        pageSize: 4,
        page: 1,
        orderType: REVIEW_TYPE.RECOMMENDATION,
        displaySeq,
        abTestGroup,
      } as any;

      const res = await reviewService.fetchProductReviews(params);
      if (!res?.data) {
        return {};
      }
      this.bestReviewList = res.data;
      return this.bestReviewList;
    },

    async toggleFavorite(displaySeq: number) {
      this.selectedProduct.favoriteYn = this.selectedProduct.favoriteYn == 'Y' ? 'N' : 'Y';
      if (this.selectedProduct.favoriteCount == null) {
        this.selectedProduct.favoriteCount = 0;
      }
      this.selectedProduct.favoriteCount =
        this.selectedProduct.favoriteYn == 'Y'
          ? this.selectedProduct.favoriteCount + 1
          : this.selectedProduct.favoriteCount - 1;
      if (this.selectedProduct.favoriteYn == 'Y') {
        await mainService.addFavorite(displaySeq);
      } else {
        await mainService.deleteFavorite(displaySeq);
      }
    },

    async fetchAlarmDisplaySeq(displaySeq: number) {
      const res = await mainService.fetchAlarmDisplaySeq(displaySeq);
      if (!res?.data) {
        return;
      }
      const { data } = res;

      this.alarmDisplay = data.data.items;
      // 여기 코드는 ProfileImageSlider 에 넘기는 이미지 값이 '' 이거나 null, undefined 이면 기본 이미지로 대체 하게 하려는 코드 이다.
      // 하드코딩된 값은 해당 api 에서만 사용하므로 여기선 사용한다.
      if (this.alarmDisplay.alarmCount > 0 && this.alarmDisplay.userProfileImages.length < 6) {
        this.alarmDisplay.userProfileImages = [
          ...this.alarmDisplay.userProfileImages,
          ...Array.from({ length: 6 }, (_) => ''),
        ].slice(0, this.alarmDisplay.alarmCount);
      }
    },
    resetAlarmDisplaySeq() {
      // 스토어 자체를 날리기엔 기존 로직도 재점검해야해서 일부만 날리게 작업
      this.alarmDisplay.alarmCount = 0;
      this.alarmDisplay.userProfileImages = [];
    },
    setMainPopupVisible(flag: boolean) {
      this.isMainPopupOpened = flag;
    },
    setMainPopupQueue(queue: any[]) {
      this.mainPopupQueue = queue;
    },
    // gnb 메뉴 리스트
    async fetchGnbMenuList() {
      const res = await mainService.fetchGnbMenu();
      this.gnbMenuList = res.data.items.reduce((p, c) => [...p, ...c.menus], [] as MenuType[]);
      return res;
    },
    // 퀵메뉴 리스트
    async fetchQuickMenuList() {
      const res = await mainService.fetchQuickMenu();
      this.quickMenuList = res.data.items.reduce(
        (acc, cur) => [...acc, ...(cur.menus.length > 0 ? [cur.menus[0]] : [])],
        [] as MenuType[],
      );
      return res;
    },
    setCompletePurchasePopup(flag: boolean) {
      this.completePurchasePopup = flag;
    },
    setVideoPreviewPopupContent(params: { title: string; description: string; videoUrl: string }) {
      const { title, description, videoUrl } = params;

      this.videoPreviewPopupContent = { title, description, videoUrl };
    },
    setShowVideoPreviewPopup(flag: boolean) {
      this.showVideoPreviewPopup = flag;
    },
    setSignupEventPopup(flag: boolean) {
      this.signupEventPopup = flag;
    },
    toggleSignupPopupType(flag: null | 'DIALOG' | 'BANNER' = null) {
      if (flag) {
        this.signupPopupType = flag;
        return;
      }
      this.signupPopupType = this.signupPopupType === 'DIALOG' ? 'BANNER' : 'DIALOG';
    },

    setMobileBestReviewPopup(flag: boolean) {
      this.mobileBestReviewPopup = flag;
    },
    setMobileBestReviewItem(item: ReviewContent) {
      this.selectedBestReview = item;
    },
    setMobileLectureAchievementPopup(flag: boolean) {
      this.mobileLectureAchievementPopup = flag;
    },
    setMobileLectureAchievementItemIndex(index: number) {
      this.selectedLectureAchievementIndex = index;
    },
  },
  getters: {
    mainDesignPupupList(state) {
      return Utility.getDesignPopupList(state.designPopupList, 'M');
    },
    categoryDesignPupupList(state) {
      return Utility.getDesignPopupList(state.designPopupList, 'C');
    },
    boardDesignPupupList(state) {
      return Utility.getDesignPopupList(state.designPopupList, 'B');
    },
    getMainPopupOpened(state) {
      return state.isMainPopupOpened;
    },
    getMainPopupQueue(state) {
      return state.mainPopupQueue || [];
    },
    getMainDesignProductList(state) {
      const filteredList = state.designModuleMainProductList.map((v) => {
        v.productList.filter((item) => {
          return item.useYn === 'Y';
        });
        return v;
      });
      return filteredList;
    },
    getShowVideoPreviewPopup(state) {
      return state.showVideoPreviewPopup;
    },
    getSignupEventPopup(state) {
      return state.signupEventPopup;
    },
    getSignupPopupType(state) {
      return state.signupPopupType;
    },
    getMobileBestReviewPopup(state) {
      return state.mobileBestReviewPopup;
    },
    getMobileBestReviewItem(state) {
      return state.selectedBestReview;
    },
    getMobileLectureAchievementPopup(state) {
      return state.mobileLectureAchievementPopup;
    },
    getMobileLectureAchievementItemIndex(state) {
      return state.selectedLectureAchievementIndex;
    },
    getMainWeolbuStudyList(state) {
      return state.designModuleMainWeolbuStudyList;
    },
    getGnbMenulistForStructuredData({ gnbMenuList }) {
      return gnbMenuList.map((item) => {
        return {
          ...item,
          title: `${item.cateName}`,
          linkUrl: `/class?cateSeq=${item.cateSeq}`,
        };
      });
    },
  },
});
