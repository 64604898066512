<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { Provider } from '~/policy';

import { Campaign } from '~/libs/ABTest';
import Tracker from '~/libs/Tracker';

import { useCouponStore } from '~/services/coupon/store';

import { StorageItem } from '~/shared/StorageItem';
import AuthClient, { REDIRECT_URI } from '~/shared/auth/client';

import { IconGoogle, IconNaver } from '~/components/common/Icons';

const props = defineProps<{
  email: string;
  userPwd: string;
}>();
const emits = defineEmits(['signin', 'update:email', 'update:userPwd']);

let redirectUriItem;
const route = useRoute();
const router = useRouter();
const isCommunityPage = computed(() => route.path?.includes('/community')) ?? false; // 커뮤니티 페이지인지 체크
const isWbTestPage = computed(() => route.path?.includes('/wbtest')) ?? false; // 계산기/테스트 페이지인지 체크

const couponStore = useCouponStore();

const authClient = ref<AuthClient | null>(null);
// ABTEST CLSQ-310
const isGroupB = useIsGroupB(Campaign['CLSQ-310']);
const is3838 = computed(() => route.path?.includes('/product/3838'));

const onLogin = useDebounceFn((provider: Provider) => {
  Tracker['Click Login Detail Button'](provider);
  StorageItem.local('PROVIDER_MODE').remove();
  redirectUriItem.set(router.currentRoute.value.fullPath);
  authClient.value?.signIn({
    provider,
    redirectUri: router.currentRoute.value.fullPath,
  });
}, 400);

const userName = computed({
  get: () => props.email,
  set: (value: string) => emits('update:email', value),
});
const password = computed({
  get: () => props.userPwd,
  set: (value: string) => emits('update:userPwd', value),
});

onMounted(async () => {
  redirectUriItem = StorageItem.local(REDIRECT_URI);
  authClient.value = AuthClient.create();
});
</script>

<template>
  <div v-if="isCommunityPage || isWbTestPage" class="wb-dialog-login__gift">
    <img src="~/assets/images/img_benefit.png" alt="부자칼럼과 자료" />
    <div class="wb-dialog-login__gift__text-container">
      <div class="wb-dialog-login__gift__text-container-upper">지금 가입 하고</div>
      <div class="wb-dialog-login__gift__text-container-lower"><b>너나위님 칼럼과 자료를 무료</b>로 받으세요!</div>
    </div>
  </div>
  <div v-else class="wb-dialog-login__gift">
    <template v-if="isGroupB && is3838"
      ><img src="~/assets/images/img_play@3x.webp" alt="재생" />
      <div class="wb-dialog-login__gift__text-container">
        <div class="wb-dialog-login__gift__text-container-upper">3초만에 가입하고</div>
        <div class="wb-dialog-login__gift__text-container-lower"><b>무료강의</b> 들어보세요!</div>
      </div></template
    >
    <template v-else>
      <img src="~/assets/images/img_coupon.png" alt="할인쿠폰" />
      <div class="wb-dialog-login__gift__text-container">
        <div class="wb-dialog-login__gift__text-container-upper">지금 가입 하고</div>
        <div class="wb-dialog-login__gift__text-container-lower">
          <b>총 {{ couponStore.totalDiscountedPrice / 10000 }}만원 할인쿠폰 </b>받으세요!
        </div>
      </div>
    </template>
  </div>
  <div class="wb-dialog-login__block">
    <Button
      class="wb-login-form__form wb-login-form__kakao"
      label="카카오로 3초만에 시작하기"
      severity="secondary"
      outlined
      icon="pi pi-kakao"
      @click="onLogin(Provider.KAKAO)" />
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--center wb-dialog-login__block--margin-top-8">
    <div class="signup-button-wrapper" @click="onLogin(Provider.NAVER)">
      <IconNaver style="width: 24px; height: 24px" />
      <p class="sns-button-title">네이버</p>
    </div>
    <span>ㅣ</span>
    <div class="signup-button-wrapper" @click="onLogin(Provider.GOOGLE)">
      <IconGoogle />
      <p class="sns-button-title">구글</p>
    </div>
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--margin-top-24">
    <InputText v-model="userName" type="text" placeholder="이메일 또는 아이디" class="w-full" />
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--margin-top-8">
    <InputText
      v-model="password"
      type="password"
      placeholder="비밀번호"
      class="w-full"
      @keydown.enter="emits('signin')" />
  </div>
</template>

<style lang="scss">
.wb-login-form__form {
  margin-top: 24px;
}
.wb-dialog-login__gift {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 12px;

  & > img {
    height: 48px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #1c2a4b;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 20px; /* 137.5% */
    }
    &-upper {
    }
    &-lower {
      b {
        color: #1f5af2;
        font-size: 16px;
        font-weight: 700;
        @media screen and (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }
}

.p-inputtext.p-invalid.p-component {
  border-color: #e34935;
}

.wb-dialog-login.v-popper--theme-tooltip {
  z-index: 1101 !important;
}
.wb-login-form__kakao {
  background-color: $kakao-bg !important;
  border: none !important;
  width: 100%;
}
.wb-login-form__form {
  @media screen and (max-width: 992px) {
    max-width: 100%;
    margin-top: 16px;
  }
}
.signup-button-wrapper {
  width: 120px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 2px;
  }

  & + span {
    display: inline-block;
    margin: 0 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #091e4229;
  }
}
.sns-button-title {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: $md) {
  .sns-button-title {
    font-size: 14px;
  }
  .pi-kakao {
    width: 20px;
    height: 20px;
  }
}

.wb-dialog-login {
  position: relative;
  width: 500px;
  min-width: 500px;

  button {
    cursor: pointer;
  }
  &__logo-container {
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 44px;
    height: 44px;
    padding: 0;
    border: 0;
    background: #ffffff00 url(../public/icon/Close-btn-x2.svg) no-repeat 50% 50%;

    @media screen and (max-width: 992px) {
      position: static;
      right: auto;
      width: 24px;
      height: 24px;
      background: #ffffff00 url(../public/icon/Close-btn-x1.svg) no-repeat 50% 50%;
    }

    .hide {
      display: none;
    }
  }

  &__kakao-btn {
    border: 0;
    width: 100%;
    background: #fee500;
    border-radius: 4px;
    font-size: 0;
  }
  &__sns-btn {
    border: 0;
    background: #ffffff00;
    font-size: 0;
  }

  &__normal-login-btn {
    font-size: 16px;
    font-weight: 700;
  }

  &__block-captcha {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;

    img {
      width: 100%;
      height: 70px;
      border-radius: 8px;
      margin-right: 8px;
      @media screen and (max-width: $md) {
        border-radius: 4px;
      }
    }

    .p-inputtext {
      background-color: #eeeeee;
    }

    .p-button {
      display: flex;
      flex-direction: column;
      min-width: 73px;
      width: 73px;
      height: 70px;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      @media screen and (max-width: $md) {
        border-radius: 4px;
      }

      .pi-refresh {
        width: 16px;
        height: 16px;
        margin-right: 0;
        margin-bottom: 2.5px;
        background: url(../public/icon/refresh.svg) no-repeat 50% 50%;
        background-size: cover;

        &::before {
          display: none;
        }
      }
      &.p-button-outlined {
        color: #eee;
        &:enabled:hover {
          color: #eee;
        }
        .p-button-label {
          color: #1c2a4b;
        }
      }
    }
  }

  &__block-captcha__img {
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    margin-right: 8px;
    @media screen and (max-width: $md) {
      border-radius: 4px;
    }
    img {
      transform: scale(1.1);
    }
  }

  &__block-captcha-error {
    display: flex;
    .p-error {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 600;
      color: #e34935;
      @media screen and (max-width: $mobile) {
        margin-bottom: 4px;
      }
    }
  }

  &__block {
    font-size: 0;

    > label {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #444444;
    }

    button {
      padding: 0;
    }

    &--logo {
      display: block;
      min-width: 170px;
      width: 170px;
      height: 24px;
      margin: 0 auto;
      background: url(../public/images/logo.svg);
      background-size: cover;
      font-size: 0;
      @media screen and (max-width: 992px) {
        min-width: 160px;
        width: 160px;
        height: 22px;
      }
    }

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        height: 100%;
      }
    }

    &--margin-top-8 {
      margin-top: 8px;
    }

    &--margin-top-16 {
      margin-top: 16px;
    }

    &--margin-top-20 {
      margin-top: 20px;
    }

    &--margin-top-24 {
      margin-top: 24px;
    }

    &--margin-top-32 {
      margin-top: 32px;
    }

    &--margin-top-48 {
      margin-top: 48px;
    }
    &--pale-sky {
      color: #656f85;
      .p-button {
        height: auto;
        padding: 11.5px 0;
        font-weight: 500;
        color: currentColor;
        &.p-button-link:enabled:hover {
          color: currentColor;
        }
      }
    }
    &--text-align-left {
      text-align: left;
    }
    &--error-message {
      margin-top: 48px;
      font-weight: 400;
      color: #e34935;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: 992px) {
        margin-top: 32px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      font-weight: 500;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 992px) {
    &__header {
      position: fixed;
      width: calc(100% - 32px);
      height: 57px;
      display: flex;
      align-items: center;
      margin: 0 16px;
    }
    &__container {
      margin: 57px 0px 0px 0px;
      height: 100vh;
      overflow: hidden;
    }
  }
}
.wb-dialog-common {
  $this: &;
  width: 100%;
  max-width: 500px;
  min-width: auto;
  max-height: 100% !important;

  button {
    cursor: pointer;
  }
  &__header {
    display: flex;
    @media screen and (min-width: $mobile + 1) {
      margin-bottom: 48px;
      flex-direction: row-reverse;
      justify-content: center;
    }
  }
  .p-dialog-content {
    position: relative;
    #{$this}__close-btn {
      @media screen and (min-width: $mobile + 1) {
        position: absolute;
        top: 24px;
        right: 24px;
      }
      width: 44px;
      height: 44px;
      padding: 0;
      border: 0;
      background: #ffffff00 url(../public/icon/Close-btn-x2.svg) no-repeat 50% 50%;
      @media screen and (max-width: 992px) {
        width: 24px;
        height: 24px;
        background: #ffffff00 url(../public/icon/Close-btn-x1.svg) no-repeat 50% 50%;
      }

      .hide {
        display: none;
      }
    }
  }
  &__container {
    text-align: center;
    @media screen and (max-width: $md) {
      padding: 0 16px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    @media screen and (max-width: $mobile) {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* color: #000; */
    }
  }
  &__content {
    margin: 0;
  }
  &__footer {
    @media screen and (min-width: $mobile + 1) {
      margin-top: 72px;
    }
  }
  @media screen and (max-width: $mobile) {
    &__header {
      padding: 16px 0;
    }
    &__close-btn {
      & + #{$this}__title {
        margin-left: 16px;
      }
    }
    &__footer {
      margin-top: 46px;
    }
    padding: 0;
  }
}

// 로그인 팝업
.wb-dialog-login {
  .p-dialog-content {
    overflow: hidden;
    padding: 56px 0px;
  }
  &__container {
    max-height: calc(var(--vh, 1vh) * 100 - 60px);
    padding: 0px 40px;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: $md) {
      padding: 0 16px;
    }
  }
}

.bottom-nav {
  padding-bottom: 64px;
}
</style>
