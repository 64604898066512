import { useUserProfileStore } from '~/services/profile';
import { useUserAuthStore } from '~/services/userAuth';
import { userCreatorService } from '~/services/userCreator/service';

type TGetCreatorInviteCodeMappingType = keyof typeof getCreatorInviteCodeMapping;

const getCreatorInviteCodeMapping = {
  class: userCreatorService.getClassCreatorReferralCode.bind(userCreatorService),
  community: userCreatorService.getCommunityCreatorReferralCode.bind(userCreatorService),
};

export const REFERRAL_STORAGE_KEY = 'wb:invitation:referrer';
export const REFFERAL_UTM_SET = {
  creator: {
    utm_source: 'creator',
    utm_medium: 'referral',
    utm_campaign: 'creator_share_button',
  },
  user: {
    utm_source: 'user_share',
    utm_medium: 'referral',
    utm_campaign: 'user_share_button',
  },
} as const;

const useUserInviteCode = () => {
  const route = useRoute();
  const userAuthStore = useUserAuthStore();
  const profileStore = useUserProfileStore();

  const myInviteCode = computed(() => {
    if (!profileStore.profile) {
      return '';
    }

    return profileStore.profile.myInviteCode;
  });

  const checkIsCreatorInviteCode = async (type: TGetCreatorInviteCodeMappingType, contentId?: string | number) => {
    try {
      if (!contentId || !userAuthStore.user || userAuthStore?.user?.creatorYn !== 'Y') {
        return false;
      }

      const { data } = await getCreatorInviteCodeMapping[type](String(contentId));
      const referralCode = data.items?.referralCode;

      return referralCode === myInviteCode.value;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const getReferrerInviteCode = () => {
    const referrerInviteCode =
      route.query.inviteCode || JSON.parse(sessionStorage.getItem(REFERRAL_STORAGE_KEY) || '{}')?.referrerInviteCode;

    return referrerInviteCode;
  };

  const getReferralCode = () => {
    // 상페에서만 사용중
    return route?.query.referralCode || null;
  };

  const memoReferrerInviteCode = () => {
    const referrerInviteCode =
      route.query?.inviteCode || JSON.parse(sessionStorage.getItem(REFERRAL_STORAGE_KEY) || '{}')?.referrerInviteCode;

    if (referrerInviteCode && !userAuthStore.user) {
      sessionStorage.setItem(
        REFERRAL_STORAGE_KEY,
        JSON.stringify({
          referrerInviteCode: referrerInviteCode,
          myInviteCode: '',
          utmSet: null,
        }),
      );
    }
  };

  return {
    myInviteCode,
    getReferralCode,
    checkIsCreatorInviteCode,
    getReferrerInviteCode,
    memoReferrerInviteCode,
  };
};

export default useUserInviteCode;
