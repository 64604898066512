import { type Pagination, type PaginationData, type PaginationOptions } from '~/types/global';

/**
 * enum
 */

/**
 * type 선언
 */
export type DisplayOptionType = 'CLASS' | 'GROUP' | 'OFFLINE_GROUP' | 'TEACH'; // 강의 | 조편성 | 오프라인_조편성 | 코칭

/**
 * interface 선언
 */
export enum PaymentWait {
  DO_PAYMENT = 1, // 결제하기
  DO_WAIT_PAYMENT = 2, // 대기신청
  DO_END_PAYMENT = 3, // 판매종료
  DO_OPEN_ALERT = 4, // 오픈알림신청
  DO_OPEN_ALERT_CANCEL = 5, // 오픈알림취소
  DO_WAIT_CANCEL = 6, // 결제대기취소
}

export enum PaymentButtonCode {
  ACTIVE = 1, // "결제하기"
  WAIT_REQUEST = 2, // "대기신청 하기"
  ENDED = 3, // "판매종료"
  ALARM_REQUEST = 4, // "오픈 알림 신청하기"
  ALARM_CANCEL = 5, // "오픈 알림 취소하기"
  WAIT_CANCEL = 6, // "대기 취소"
  AUTH_NOT_ALLOW = 7, // "권한이 없습니다."
  ALARM_COMPLETED = 8, // "오픈 알림 신청 완료"
  ALARM_OPEN_AT = 9, // "OPEN_DATE 오픈 예정"
  WAIT_REQUESTED = 10, //  "대기신청 완료"
}

export enum DesignModuleType {
  BANNER = 'BANNER', // 상단띠배너
  SLIDE = 'SLIDE', // 슬라이드배너
  PRODUCT = 'PRODUCT', // 상품/강의
  WEOLBU_STUDY = 'WEOLBU_STUDY', // 월부 스터디
  BOARD = 'BOARD', // 게시판
  ROOM = 'ROOM', // 나의강의실
  MYPAGE = 'MYPAGE', // 마이페이지
  FAQ = 'FAQ', // FAQ
}
export enum DesignType {
  MAIN = 'MAIN', // 메인
  SUB = 'SUB', // 서브
}
export enum LinkType {
  P = 'P', // 페이지이동
  N = 'N', // 새창
}

export interface DesignModuleProduct {
  designModuleProductSeq?: number;
  designSeq: number;
  displaySeq: number;
  displayName: string;
  productOrder: number;
  regId?: string | null;
  regDate?: string;
}

export interface DesignModuleProductLecture {
  designSeq: number;
  designType: string; // MAIN - 메인 SUB - 서브
  designModuleType: string;
  designModuleName: string;
  displayOrder: string; // 노출순서 N 최신순 S 조회순 F 추천순
  thumnailType: string; // 섬네일 유형 B - 크게 S - 작게
  priceViewYn: string; // Y 사용, N 사용안함
  tagViewYn: string; // Y 사용, N 사용안함
  designModuleProductList: DesignModuleProduct[];
}

export interface DesignModuleSlide {
  designSlideSeq: number;
  designSeq: number;
  slideTitle: string;
  slideTitleColorHex: string;
  slideSubTitle: string;
  btnTitle: string;
  btnFontColorHex: string;
  btnBackgroundColorHex: string;
  pcBackgroundType: string | 'H' | 'I';
  pcBackgroundColorHex: string;
  pcBackgroundImgUrl: string | null;
  pcBackgroundImgOrg: string | null;
  pcImageUrl: string | null;
  pcImageOrg: string | null;
  linkType: LinkType;
  linkUrl: string;
  startDate: string | null;
  endDate: string | null;
  moBackgroundType: string | 'H' | 'I';
  moBackgroundColorHex: string | null;
  moBackgroundImgUrl: string | null;
  moBackgroundImgOrg: string | null;
  moImageUrl: string | null;
  moImageOrg: string | null;
  slideOrder: number;
  useYn: string;
  regId: string | null;
  regDate: string;
}

export interface DesignModule {
  designSeq: number;
  designType: DesignType;
  designModuleType: DesignModuleType;
  designModuleName: string;
  designBackgroundColorHex: string;
  designBackgroundType: string;
  pcImageUrl: string;
  pcImageOrg: string;
  mobileImageUrl: string;
  mobileImageOrg: string;
  designStartDate: string;
  designEndDate: string;
  linkType: LinkType;
  linkUrl: string;
  useYn: string;
  regId: string;
  regDate: string;
  priceViewYn: string; // Y 사용, N 사용안함
  tagViewYn: string; // Y 사용, N 사용안함
  thumnailType: string; // 섬네일 유형 B - 크게 S - 작게
  slideList: DesignModuleSlide[];
  productList: Product[];
  recentProductList: DisplayProduct[];
  isImageBannerUsed: boolean;
}

export interface WeolbuStudyDesignModule extends DesignModule {
  isImageBannerUsed: boolean;
  pcImageUrl: string;
  pcImageOrg: string;
  mobileImageUrl: string;
  mobileImageOrg: string;
  displayTarget: 'TR' | 'ALL' | 'ZERO';
}

export interface CachedPopupData {
  seq: number;
  type: string;
  lastOpenedDate?: Date;
}

export interface DesignPopup {
  designPopupSeq: number;
  designPopupTitle: string;
  designPopupViewArea: string;
  designPopupPcImageUrl: string;
  designPopupPcImageOrgName: string;
  designPopupMobileImageUrl: string;
  designPopupMobileImageOrgName: string;
  designPopupFrameType: string;
  designPopupLinkType: LinkType;
  designPopupLinkUrl: string;
  designPopupStartDate: string;
  designPopupEndDate: string;
  designPopupWidthSize: number;
  designPopupHeightSize: number;
  designPopUpCategoryList: DesignPopUpCategory[];
  ui_visible: boolean;
  ui_is_open_oneday: boolean;
}

export interface DesignPopUpCategory {
  catePSeq: number;
  cateSeq: number;
  designPopupCategorySeq: number;
  designPopupSeq: number;
  displaySeq: number;
}

export interface DesignDisplayCategory {
  designModuleCateSeq?: number;

  cateSeq?: number;
  // catePath?: string;
  displaySeq: number;
  displayCatePath?: string; // 카테고리 path. ex) 대분류>중분류
  displayName?: string;
  pcImageUrl: string;
  pcImageOrg: string;
  mobileImageUrl: string;
  mobileImageOrg: string;
  linkType: string;
  linkUrl: string;
  startDate: string;
  endDate: string;
  useYn: string;
  regId: string;
  regDate: string;
  modId: null | string;
  modDate: null | string;
}
export enum SurveyType {
  AREA = 'AREA',
  JOB = 'JOB',
  POS = 'POS',
  HEAD = 'HEAD',
  ADMIN = 'ADMIN',
  SEX = 'SEX',
}

export interface DisplayProductSurveyDetail {
  surveyDetailSeq?: number;
  surveySeq?: number;
  surveyType: SurveyType;
  surveyDetailName: string;
  regId?: string;
  regDate?: string;
}

export interface DisplayProductSurvey {
  surveySeq: number;
  surveyName: string;
  surveyAreaYn: string;
  surveyJobYn: string;
  surveyPosYn: string;
  surveyHeadYn: string;
  surveyAdminYn: string;
  surveySexYn: string;
  surveyConnectCount: number; // 사용차수
  regId: string;
  regDate: string;
  modId: string;
  modDate: string;
  surveyDetail?: DisplayProductSurveyDetail[];
}

// DisplayCreateForm
export interface DisplayProduct {
  displaySeq?: number | null; // 숨겨진 값
  displayCategoryId?: number | null; // 숨겨진 값
  productName?: string;

  productSeq?: number | null; // tb_product 키
  displayCatePath: string;
  displayName: string; // 전시 상품명
  price: number; // 정가
  earlyBirdYn: string; // 얼리버드 할인, 'Y' 사용, 'N' 사용 안함
  discountPrice: number | null; // 기본 판매가
  firstDiscountPrice: number | null; // 1차 할인가
  firstDiscountNum: number | null; // 1차 할인가 인원 수
  secondDiscountPrice: number | null; // 2차 할인가
  secondDiscountNum: number | null; // 2차 할인가 인원 수
  thirdDiscountPrice: number | null; // 3차 할인가
  thirdDiscountNum: number | null; // 3차 할인가 인원 수
  optionYn: string; // 'Y' 옵션 사용, 'N' 옵션 미사용
  optionCountYn: string; // 'Y' 옵션 수량 제한 설정, 'N' 옵션 수량 제한 없음
  optionCount: number | null; // 옵션 판매 수량
  groupYn: string; // 'Y' 조편성 사용, 'N' 조편성 미사용
  surveySeq: number | null; // 설문키
  stayYn: string; // 'Y' 대기신청 가능, 'N' 미사용
  previewYn: string; // 'Y' 맛보기 사용, 'N' 사용 안함
  previewUrl: string; // 맛보기 연결 URL
  saleType: 'P' | 'N' | 'A'; // 'P' 기간판매, 'A' 상시판매, 'N' 판매불가
  saleStartDt: string; // 기간판매 시작시점
  saleEndDt: string; // 기간판매 종료 시점
  courseType: string; // 'P' 수강기간, 'D' 수강일
  courseStartDate: string; // 수강 시작일
  courseEndDate: string; // 수강 종료일
  courseDay: number | null; // 수강일
  refundDay: number | null; // 환불 가능일
  displayTypeCd: string; // 디스플레이 타입
  requiredDisplayYn: string; // 'Y' 필수 선택 상품 사용, 'N' 사용 안함
  displayWithYn: string; // 'Y' 관련 상품 사용, 'N' 사용 안함
  breakClassYn: string; // 'Y' 휴강 사용, 'N' 사용 안함
  displayThumbnailUrl: string; // 썸네일 URL
  displayThumbnailOrg: string; // 썸네일 원본 파일명
  displayPcContent: string; // PC 본문
  displayMoContent: string; // 모바일 본문
  displaySeoTitle: string; // 상품 SEO-title
  displaySeoDesc: string; // 전시상품 설명
  displaySeoThumbnailUrl: string; // 전시상품 SEO 섬네일 URL
  displaySeoThumbnailOrg: string; // 전시상품 SEO 섬네일 원본파일명
  useYn: string; // 'Y' 사용, 'N' 사용 안함
  regId: string;
  regDate: string;
  reviewPoint: string;
  reviewCount: number;
  monthPlan?: number;
  endYn: string; // 마감;
  endDateYn: string; // 날짜가 지나서 마감
  alarmYn: string; // 알림신청
  todayEndYn: string; // 오늘마감
  tomorrowEndYn: string; // 내일마감
  originalYn: string;

  displayAuthList?: DisplayAuth[];
  displayOptionList?: DisplayOption[];
  displayRequiredList?: DisplayRequired[];
  displaySearchTagList?: DisplaySearchTag[];
  displaySeoKeywordList?: DisplaySeoKeyword[];
  displayTags?: DisplayTag[];
  displayWithList?: DisplayWith[];
  displayCategoryMappedList?: DisplayCategoryMapped[];
  creatorNames?: string[];
  curSeq?: number;

  productCateSeq: string;
  isLiveStudy: boolean;
  liveStudyStartAt: string;
  liveStudyEndAt: string;

  weolbuStudyPaymentCompleteLandingUrl?: string;
  weolbuStudyPaymentCompleteButtonText?: string;

  isWeolbuStudyRedirect: boolean;
  weolbuStudyRedirectDisplayId?: number;

  isHonorsRequiredForPurchase: boolean;

  // 수강형태
  classType?: string;
  // 수강조건
  courseRequirements?: string;
  // 학습정책 약관
  learningPolicy?: string;
  // 환불정책 약관
  refundPolicy?: string;
  // 기타 유의사항
  isAdditionalNoticeUsed?: boolean;
  additionalNotice?: string;

  redirectDisplayId?: number; // 리다이렉트할 전시상품 ID
  displayType?: 'NORMAL' | 'GIFT_CARD'; // 전시상품 타입,일반 : NORMAL, 상품권 : GIFT_CARD
}

export interface DisplayTag {
  tagName: string;
  tagBackColor: string;
  tagFontColor: string;
  useYn: 'Y' | 'N';
  tagSeq: number;
  regId: string;
  regDate: string;
}

// DisplayOptionCreateForm
export interface DisplayOption {
  displaySeq: number; // 전시상품 키
  optionName?: string; // 옵션명
  optionDesc?: string; // 옵션 설명
  optionQtyType: string; // 옵션 수량 타입 옵션 수량 N - 수량제한 없음,  Y - 수량제한 설정
  optionQty: number | null; // 판매 가능 수량
  optionPrice: number | null; // 옵션 가격
  optionTypeCd: DisplayOptionType;
  teachingDate: string; // 코칭 일자
  regId?: string;
  regDate?: string;
}

// DisplayRequiredCreateForm
interface DisplayRequired {
  displaySeq?: number; // 전시상품 키
  mappedDisplaySeq?: number; // 전시상품 카테고리 키
  displayName?: string;
  regId?: string;
  regDate?: string;
}

// DisplaySearchTagCreateForm
export interface DisplaySearchTag {
  displaySeq?: number | null; // 전시상품 키
  searchTagSeq?: number | null; // 태그 키
  searchTagName: string; // 전시 상품 검색 태그
  regId?: string;
  regDate?: string;
}

// DisplaySeoKeywordCreateForm
export interface DisplaySeoKeyword {
  displaySeq?: number; // 전시상품 키
  seoKeyword: string; // 키워드
  regId?: string;
  regDate?: string;
}
export interface DisplayCategoryMapped {
  displaySeq?: number;
  displayCateSeq?: number;
  displayCateName: string;
  regId?: string;
  regDate?: string;
}

export interface DisplayWith {
  displaySeq: number; // 전시상품 키
  mappedDisplaySeq: number; // 전시상품 카테고리 키
  displayName: string;
  regId?: string;
  regDate?: string;
}
export interface DisplayGroupFilterDetail {
  surveyDetailSeq: number;
  surveySeq: number;
  surveyType: string;
  surveyDetailName: string;
  regId?: string;
  regDate?: string;
}

export interface DisplayGroupFilterDetailList {
  area: DisplayGroupFilterDetail[];
  sex: DisplayGroupFilterDetail[];
  pos: DisplayGroupFilterDetail[];
  job: DisplayGroupFilterDetail[];
}

export interface DisplayGroupFilter {
  displaySeq?: number;
  displayName: string;
  surveySeq?: number;
  surveyName: string;
  surveyAreaYn: 'Y' | 'N';
  surveyJobYn: 'Y' | 'N';
  surveyPosYn: 'Y' | 'N';
  surveyHeadYn: 'Y' | 'N';
  surveyAdminYn: 'Y' | 'N';
  surveySexYn: 'Y' | 'N';
  surveyDetailList: DisplayGroupFilterDetailList;
}
export interface UserGroupDetail {
  userId: number;
  email: string;
  userName: string;
  groupAdminYn: string;
  groupLeaderYn: string;
  refundYn: 'Y' | 'N';
  surveyAreaName: string;
  surveyJobName: string;
  surveyPosName: string | null;
  surveySexName: string | null;
  surveySeq: number;
  groupLeadConfirmYn: 'Y' | 'N';
  groupAdminConfirmYn: 'Y' | 'N';
  groupSeq: string;
  groupNumber: string;
}

export interface DisplayUserGroup {
  groupSeq?: number;
  displaySeq?: number;
  groupName?: number;
  groupNumber?: number;
  groupConfirmYn: 'Y' | 'N';
  excludeYn: 'Y' | 'N';
  regId?: string | null;
  regDate?: string | null;
  userGroupDetailList: UserGroupDetail[];
}
export interface ClassCreator {
  creatorSeq: number;
  creatorId: string;
  creatorField: string | null;
  creatorImgUrl: string;
  creatorImgOrg: string;
  creatorInfo: string;
  userName: string;
}
export interface ProductWithPaging {
  items: Product[];
  cateSeq: number | string | null;
  paging: ClassByCategoryListOptions;
}
export interface ClassByCategoryListOptions extends Pagination {
  cateSeq: number | string;
}

export interface Captcha {
  captchaId: string;
  imageUrl: string;
}

export interface ProductReview {
  reviewSeq: number;
  reviewTitle: string;
  reviewText: string;
  displaySeq: string;
  productSeq: string;
  reviewPoint: string;
  viewCount: string;
  commentCount: string;
  likeCount: string;
  regId: number;
  userName: string;
  nickName: string;
  userProfileImgUrl: null | string;
  userProfileInfo: null | string;
  regDate: string;
  likeYn: string;
  isBestReview: boolean;
}
export interface ReviewListWithPaging extends PaginationData {
  items: ProductReview[];
}
export interface Product extends DisplayProduct {
  primeCost: null;
  mdName: string;

  classCategoryList?: ClassCategory[];

  classWithProductList?: any[];
  classCreatorList?: ClassCreator[];
  classCurriculumList?: ClassCurriculum[];

  bottomBanner?: DesignDisplayCategory;

  bestReviewPoint: string | null;
  bestReviewCount: number;
  recentCount: number | null;
  favoriteCount: number;
  favoriteYn: string;

  paymentWait: number | null;

  productOption?: ProductOption[];
  // availableCoupon: Coupon[];
  availableCouponOfMine?: Coupon[];
  availablePoint: number;
  giftCardTotalBalance: number; // 상품권 전체 금액
  classPrice?: ClassPrice;
  bestReviewList?: ReviewListWithPaging;
  reviewList?: ReviewListWithPaging;
  stayPaymentYn: string | null;
  captcha?: Captcha | null;

  captchaYn?: 'Y' | 'N';
  productCategorySeq: number;
  // isPaymentWaitPaymentPossible: boolean; // TODO: [FS-433](주문결제 페이지 다이렉트 접근 불가) 근본 해결을 위한 임시처리. 추가 검토까지 추석 처리
}
export interface DisplayAuth {
  displayAuthSeq: string;
  displaySeq: string;
  authSeq: string;
  authName: string;
  regId: string;
  regDate: string;
}

export interface ClassCategory {
  parentCateName: string;
  cateName: string;
  cateSeq: string;
  catePSeq: string;
}

export interface ClassCurriculum {
  productLecSeq: number;
  productLecUuid: string;
  productLecPUuid: string;
  displaySeq: number;
  lectureTypeCd: string;
  title: string;
  seqName: string;
  creatorSeq: string;
  creatorNames: string[];
  playTime: number;
  contentSeq: string;
  evaluationSeq: string;
  evaluationType: string;
  evaluationPos: number;
  reportSeq: string;
  lectureOrder: number;
  btnName: string;
  linkUrl: string;
  useYn: string;
  regId: string;
  regDate: string;
  reportStartDate: string;
  reportEndDate: string;
  description?: string;
  isUsedPreviewUrl: boolean;
  previewUrl?: string;
}
export interface ProductOption {
  optionSeq: number;
  displaySeq: number;
  optionName: string;
  optionDesc: string;
  optionQtyType: 'Y' | 'N'; // 옵션 수량 타입 옵션 수량 N - 수량제한 없음,  Y - 수량제한 설정
  optionQty: number;
  optionPrice: number;
  optionTypeCd: DisplayOptionType;
  optionSoldQty: number;
}
export interface Coupon {
  userCouponSeq: number;
  couponSeq: number;
  couponName: string;
  couponTermsSeq: number;
  duplicateYn: string;
  discountType: string; // 'P' : percentage, 'W' : won
  discountPrice: number;
  periodType: string;
  periodStartDate: string;
  periodEndDate: string;
  period: number | null;
  maxDiscountPrice: number;
  productUseType: string;
  maxIssue: number;
  couponCode: string;
  regId: string;
  regDate: string;
  resultDiscountPrice?: number; // 정찰, 정율제 둘다 실제 할인되는값을 계산해서 넣은 변수
}

export interface ClassPrice {
  displayPrice: number;
  earlyPayment: string;
}

export interface ProductForTracking {
  index: number;
  displayName: string;
  productId: number;
  productName: string;
  categoryId: number;
  categoryName: string;
  discountRate: number;
  price: number;
  salePrice: string;
  payinguserYn: string;
}

export interface MainProductClickPayload extends DisplayProduct {
  discountPercentage: number;
  salePrice: string;
}

export type PaymentButtonDto = {
  buttonCode: number;
  buttonName: string;
  buttonDisable: boolean;
  displaySeq: number;
  displayName: string;
  optionName: string;
  optionPrice: number;
  optionQty: number;
  optionQtyType: 'Y' | 'N';
  optionSeq: number;
  optionTypeCd: DisplayOptionType;
  optionLabels: string[];
  optionDesc: string;
  isExistAuth: boolean;
  canProceedWithHonorsPurchase: boolean;
};

export type PaymentButtonCta = {
  isExistOption: boolean;
  paymentButtons: PaymentButtonDto[];
};

export type SelectedCoupon = {
  couponSeq: number;
  userCouponSeq: number;
  couponName: string;
  discountType: string;
  discountPrice: string;
  maxDiscountPrice: number;
  couponStartDt: string;
  couponEndDt: string;
  expiredTimestamp: number;
};
export interface PostUserDeviceTokenOptions {
  deviceToken: string;
  os: 'pc' | 'android' | 'ios' | '';
  isPwa: boolean;
}

export interface DeleteUserDeviceTokenOptions {
  deviceToken: string;
}

export interface AlarmDisplaySeq {
  displaySeq: number;
  alarmCount: number;
  userProfileImages: string[];
}

// GNB 및 QuickMenu 타입 정의
export interface MenuType {
  title: string;
  imageUrl: string;
  isExistedBadge: boolean;
  linkType: LinkType;
  linkUrl: string;
  startAt: string;
  endAt: string;
  createdAt: string;
}

export interface MenuListType {
  order: number;
  menus: MenuType[];
}

export interface GetReviewOptions extends PaginationOptions {
  displaySeq: null | number;
  productSeq: null | number;
  searchBestYn: String;
}

export interface ReviewCountType {
  reviewCount1Star: number;
  reviewCount2Star: number;
  reviewCount3Star: number;
  reviewCount4Star: number;
  reviewCount5Star: number;
  reviewPointAvg: number;
  reviewRatio1Star: number;
  reviewRatio2Star: number;
  reviewRatio3Star: number;
  reviewRatio4Star: number;
  reviewRatio5Star: number;
  reviewTotalCount: number;
}
