<script setup lang="ts">
import { isEmpty } from 'lodash-es';

import { SIGNUP_WELCOME_POPUP } from '~/constants/browser-storage-keys';

import { safeLocalStorage } from '~/libs/safeLocalStorage';

import { getPathResource } from '~/services/common/util/funnel';
import { useCouponStore } from '~/services/coupon';
import { useUserAuthStore } from '~/services/userAuth';
import { useInterestStore } from '~/services/users/interesttags/store';

const userAuthStore = useUserAuthStore();
const isLoggedIn = computed(() => !isEmpty(userAuthStore.user));

onMounted(() => {
  const welcomePopupSign = safeLocalStorage.getItem(SIGNUP_WELCOME_POPUP);
  if (welcomePopupSign !== 'true' || window.location.href.includes('signup') || !isLoggedIn.value) {
    return;
  }
  const { path } = getPathResource(window.location.href);

  safeLocalStorage.removeItem(SIGNUP_WELCOME_POPUP);

  const couponStore = useCouponStore();
  if (path === 'CLASS') {
    couponStore.setClassWelcomePopup(true);
    return;
  }
  if (path === 'COMMUNITY') {
    couponStore.setWelcomePopup(true);
    return;
  }
  const interestStore = useInterestStore();
  interestStore.setInterestPopup(true);
});
</script>
