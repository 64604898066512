import * as Sentry from '@sentry/vue';
import dayjs, { Dayjs } from 'dayjs';
import { floor } from 'lodash-es';

import '~/libs/initializeDayjs';

const isISODateString = (dateString: string) => {
  return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(((-|\+)(\d{2}):(\d{2})|Z))$/.test(dateString);
};

export const safeDateParse = (dateInput: string | number | Date | Dayjs) => {
  try {
    // falsy value가 오면, 현재 시간으로 반환
    if (!dateInput) {
      return dayjs.tz();
    }

    if (dateInput instanceof Date || typeof dateInput === 'number' || dayjs.isDayjs(dateInput)) {
      return dayjs.tz(dateInput);
    }

    if (!dayjs(dateInput).isValid()) {
      // 과거 일부 iOS 기기에서 YYYY-MM-DD HH:mm:ss 형식의 날짜를 파싱하지 못한 경우 -> - 대신 /로 변경
      return dayjs.tz(dateInput.replace(/-/g, '/'));
    }

    if (isISODateString(dateInput)) {
      return dayjs.tz(dayjs(dateInput));
    }

    return dayjs.tz(dateInput);
  } catch (e) {
    // 파싱이 제대로 되지 않은 케이스 -> Sentry에 로그 남김
    if ($isClient()) {
      Sentry.captureMessage('safeDateParse - 파싱하지 못한 Date', {
        extra: { dateInput },
        level: 'warning',
      });
    }

    return dayjs.tz();
  }
};

export const getTimeString = (time: string, format: string): string => {
  // Define Korean weekdays
  const weekdaysKorean = ['일', '월', '화', '수', '목', '금', '토'];

  // Create a dayjs object
  const dayjsObj = safeDateParse(time);

  // Get the weekday index (0 for Sunday, 1 for Monday, etc.)
  const weekdayIndex = dayjsObj.day();

  format = format.replace('ddd', weekdaysKorean[weekdayIndex]);
  // Replace the weekday in the formatted string
  const formattedDate = dayjsObj.format(format);

  return formattedDate.replace(/am/gi, '오전').replace(/pm/gi, '오후');
};

export const refinePlayTime = (playTime?: number): string | null => {
  if (playTime === null || playTime === undefined) {
    return null;
  }

  const totalHours = floor(dayjs.duration(playTime, 'seconds').asHours());
  const hours = totalHours < 10 ? `0${totalHours}` : totalHours;

  const formattedTime = `${hours}:${dayjs.duration(playTime, 'seconds').format('mm:ss')}`;
  return formattedTime;
};

export const now = () => {
  return dayjs.tz();
};

export const getDurationInDays = (startDate: string, endDate: string): number => {
  const start = safeDateParse(startDate);
  const end = safeDateParse(endDate);
  const duration = end.diff(start, 'days');

  return duration;
};

export const isDateIn = (startDate: string, endDate: string): boolean => {
  const start = safeDateParse(startDate);
  const end = safeDateParse(endDate);
  const nowTime = now();

  // [] : inclusion, (): exclusion
  return nowTime.isBetween(start, end, undefined, '[]');
};

export const getTimeDifference = (regDate: string): string => {
  const userArrivalTime = safeDateParse(regDate);
  const currentTime = now();

  const timeDifferenceInMinutes = currentTime.diff(userArrivalTime, 'minutes');
  const timeDifferenceInHours = currentTime.diff(userArrivalTime, 'hours');
  const timeDifferenceInDays = currentTime.diff(userArrivalTime, 'days');
  const timeDifferenceInMonths = currentTime.diff(userArrivalTime, 'months');
  const timeDifferenceInYears = currentTime.diff(userArrivalTime, 'years');

  if (timeDifferenceInMinutes < 1) {
    return `방금`;
  }

  if (timeDifferenceInHours < 1) {
    return `${timeDifferenceInMinutes}분 전`;
  }

  if (timeDifferenceInDays < 1) {
    return `${timeDifferenceInHours}시간 전`;
  }

  if (timeDifferenceInMonths < 1) {
    return `${timeDifferenceInDays}일 전`;
  }

  if (timeDifferenceInYears < 1) {
    return `${timeDifferenceInMonths}개월 전`;
  }

  return `${timeDifferenceInYears}년 전`;
};

// React에 선언된 메소드와 동일하게 구현
export const getTimeDifferenceNumber = (regDate: string): { value: number; unit: string } => {
  const to = safeDateParse(regDate);
  const from = now();

  const timeDifferenceInMinutes = to.diff(from.toDate(), 'minutes');
  const timeDifferenceInHours = to.diff(from.toDate(), 'hours');
  const timeDifferenceInDays = to.diff(from.toDate(), 'days');
  const timeDifferenceInMonths = to.diff(from.toDate(), 'months');
  const timeDifferenceInYears = to.diff(from.toDate(), 'years');
  if (timeDifferenceInMinutes < 0) {
    return { value: timeDifferenceInMinutes, unit: '' };
  }

  if (timeDifferenceInHours < 1) {
    return { value: timeDifferenceInMinutes, unit: 'minutes' };
  }

  if (timeDifferenceInDays < 1) {
    return { value: timeDifferenceInHours, unit: 'hours' };
  }

  if (timeDifferenceInMonths < 1) {
    return { value: timeDifferenceInDays, unit: 'days' };
  }

  if (timeDifferenceInYears < 1) {
    return { value: timeDifferenceInMonths, unit: 'months' };
  }

  return { value: timeDifferenceInYears, unit: 'years' };
};

export const getYYYYMMDD = (date: string) => {
  return safeDateParse(date).format('YYYY-MM-DD');
};

export const isRangeDate = (param: {
  startDateString: string;
  endDateString: string;
  nowDateString?: string;
}): boolean => {
  const startDate = safeDateParse(param.startDateString);
  const endDate = safeDateParse(param.endDateString);
  const nowDate = param.nowDateString ? safeDateParse(param.nowDateString) : now();

  return (
    nowDate.isSame(startDate) || nowDate.isSame(endDate) || (nowDate.isAfter(startDate) && nowDate.isBefore(endDate))
  );
};
// D-day 계산
export const getDday = ({
  nowDateString,
  dDayDateString,
}: {
  nowDateString?: string | null;
  dDayDateString: string;
}): number => {
  const nowDate = nowDateString ? safeDateParse(nowDateString) : now();
  const dDayDate = safeDateParse(dDayDateString);
  return dDayDate.diff(nowDate, 'days');
};
