export const NEXT_MIGRATED_PATHNAME_REGEXPS: RegExp[] = [
  /^\/class\/event\/(\d+)(\/)?/,
  /^\/mypage.*$/,
  /^\/footer.*$/,
  /^\/profile\/(\d+)(\/)?/,
  /^\/search.*$/,
  /^\/find.*$/,
  /^\/zero\/([a-zA-Z0-9]+)(\/)?/,
  /^\/class.*$/,
  /^\/community\/(\d+)(\/)?/,
  /\/class\/event\/weolbu-week/,
  /^\/community\/form\/(\d+)(\/)?/,
  /^\/product\/(\d+)(\/)?/,
  /^\/onboarding(?:\/[\w-]+)*$/,
];
export const NEXT_MIGRATED_PATHNAMES: Set<string> = new Set([
  '/class/event/discount',
  '/class/event/introduce-friend',
  '/class/event',
  '/notifications',
  '/community',
  '/curriculum',
  '/signup',
  '/community/form',
  '/',
  '/product',
]);
