import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash-es';
import { Provider } from '~/policy';
import { safeLocalStorage } from '~~/src/libs/safeLocalStorage';

import { SIGNUP_WELCOME_POPUP } from '~/constants/browser-storage-keys';

import Analytics from '~/libs/Analytics';
import Broadcast from '~/libs/Broadcast';
import ChannelService from '~/libs/ChannelService';
import Tracker from '~/libs/Tracker';
import { now } from '~/libs/dateUtils';
import { requestPermission } from '~/libs/firebaseConfig';

import { getFunnelData, getPathResource } from '~/services/common/util/funnel';
import { useCouponStore } from '~/services/coupon/store';
import { useInterestStore } from '~/services/users/interesttags/store';

import { StorageItem } from '~/shared/StorageItem';
import { PROVIDER_STATE_KEY_PREFIX, REDIRECT_URI } from '~/shared/auth/client';

import {
  type LoginOptions,
  type PostUserJoinOptions,
  type SocialLoginType,
  type SocialSignInPayload,
  type Token,
  type UserInfo,
  userAuthService,
} from '.';
import { type CachedPopupData, mainService } from '../main';

export interface OldUserInfo {
  oldEmail: string;
  type: 'social' | 'email';
  socialType: '' | SocialLoginType;
  oldId: string;
  pw: string;
}
interface State {
  token: null | Token;
  user: null | UserInfo;
  socialHistory: any;
  cachedPopupData: { [key: string]: CachedPopupData };
  oldInfo: null | OldUserInfo;
}

export const useUserAuthStore = defineStore('user-auth', {
  persist: {
    storage: process.client ? localStorage : undefined,

    afterRestore: async (context) => {
      if (!isEmpty(context.store.token)) {
        await context.store.fetchUserInfo();
      }
    },
  },
  state: (): State => ({
    token: null,
    user: null,
    socialHistory: null,
    oldInfo: null,
    cachedPopupData: {},
  }),
  actions: {
    async login(payload: LoginOptions) {
      // console.log('login :>> ', payload);
      const { data } = (await userAuthService.login(payload)) as any;
      if (!data.status.errorCode && data.data.token) {
        this.token = data.data.token;

        // Next 쪽 인증을 위한 코드
        await $fetch('/nuxt-api/set-weolbu-at', { method: 'POST', body: { token: data.data.token } });

        await this.fetchUserInfo();
        const bootOpt: any = {
          pluginKey: '024154f0-22c0-444c-a8a2-3a563b72323c',
          hideChannelButtonOnBoot: true,
        };
        const { data: data2 } = await userAuthService.getUserChannelTalkProfile();
        const {
          userId,
          availablePoint,
          email,
          marketingYn,
          nickName,
          totalPaymentCount,
          totalPaymentPrice,
          userName,
          userPhone,
        } = data2.data.items;
        bootOpt.memberId = userId;
        bootOpt.unsubscribeEmail = marketingYn === 'Y';
        bootOpt.unsubscribeTexting = marketingYn === 'Y';
        bootOpt.profile = {
          email,
          availableMileage: availablePoint,
          name: userName,
          nick: nickName,
          mobileNumber: userPhone,
          totalPurchaseCount: totalPaymentCount,
          totalPurchaseAmount: totalPaymentPrice,
        };

        ChannelService.boot(bootOpt);
        // 부트하고 바로 숨겨줌
        // 회원가입 하고 로그인 처리 할때 바로 이동 되지 않고 모달창을 띄워줘서 그때 보이기 때문에 숨김 처리함
        // 해당 페이지가 채널톡이 보여야 하는 페이지면 이동 되면서 다시 보여주기 때문에 여기서 숨김 처리 해줘도 정상으로 볼 수 있음
        ChannelService.hideChannelButton();
        if (this.user) {
          Analytics.identify(this.user);

          Tracker['Complete Login']('email');

          Sentry.setUser({
            email: this.user.email,
            id: this.user.userId,
            username: this.user.nickName,
          });

          // TODO: DATADOG 모니터링 사용자 세션 정보 설정(도입 여부에 따라 유지 또는 삭제 결정)
          datadogRum.setUser({
            id: String(this.user.userId),
            email: this.user.email,
            name: this.user.userName,
            nickName: this.user.nickName,
          });
        }
      }
      return data;
    },
    async signUp(payload: PostUserJoinOptions) {
      try {
        const { data } = await userAuthService.signUp(payload);
        await this.handleLoginResponse(data, payload);
        this.processModalOpen(data);
      } catch (error) {
        this.handleLoginError(error);
        throw error;
      }
    },
    async requestCaptchaImg() {
      const {
        data,
        headers: { identifykey },
      } = await userAuthService.requestCaptchaImg();
      return [data, identifykey];
    },
    async socialLogin(payload: SocialSignInPayload) {
      try {
        const { data } = await userAuthService.socialLogin(payload);
        await this.handleLoginResponse(data, payload);
        this.processModalOpen(data);
        return data;
      } catch (error) {
        this.handleLoginError(error);
      }
    },
    async handleLoginResponse(data, payload) {
      if (data.status.code !== 200) {
        throw data.status;
      }
      console.log('handleLoginResponse:data :>> ', data, payload);

      // 신규 정책으로 인해 주석 처리 추후 정리
      // const redirectUriItem = StorageItem.local(REDIRECT_URI);
      // const urlObject = new URL(redirectUriItem.get() || '', window.location.origin);
      // const utmMedium = urlObject.searchParams.get('utm_medium');

      const provider = StorageItem.local(PROVIDER_STATE_KEY_PREFIX).get();
      switch (data.status.errorCode) {
        case '':
          await this.processSuccessfulLogin(data, payload);
          break;
        case 'ERROR_1505':
        case 'ERROR_0014':
          this.handlePhoneDuplication(data);
          break;
        case 'ERROR_1513':
          this.migrationSocial(data);
          break;
        case 'ERROR_1508':
          if (provider === Provider.GOOGLE && data.status.errorMessage === '') {
            await $alert('구글 간편 회원가입이 종료됩니다. </br> 구글 외에 다른 계정으로 가입해 주세요.');
            this.logout();
            break;
          }
          // 아래 코드는 동작하지 않음
          if (data.data.isSignUp) {
            const redirectUri = StorageItem.local(REDIRECT_URI).get() || '/';

            const { path, resource } = getPathResource(redirectUri);
            const inflowFunnel = getFunnelData('inflow');

            Tracker['Complete Signup']({
              oauth: payload.provider,
              signupRoute: redirectUri,
              signupPath: path,
              signupResource: resource,
              signupDate: now().format('YYYY-MM-DDTHH:mm:ss'),
              ...inflowFunnel,
            });
          } else {
            Tracker['Complete Login']('email');
          }
          StorageItem.local('onetimeToken').set(JSON.stringify(data.data.response.onetimeToken));
          break;
        default:
          console.error('Unhandled error code:', data.status.errorCode);
          break;
      }
    },
    async processSuccessfulLogin(data, payload) {
      this.token = data.data.token;
      const { data: user } = await userAuthService.session();
      this.user = {
        ...user.data.items,
        lastLoginType: payload.provider ?? 'email',
      };
      if (this.user) {
        Analytics.identify(this.user, true);
        if (data.data.isSignUp) {
          const redirectUri = StorageItem.local(REDIRECT_URI).get() || '/';

          const { path, resource } = getPathResource(redirectUri);
          const inflowFunnel = getFunnelData('inflow');

          Tracker['Complete Signup']({
            oauth: payload.provider,
            signupRoute: redirectUri,
            signupPath: path,
            signupResource: resource,
            signupDate: now().format('YYYY-MM-DDTHH:mm:ss'),
            ...inflowFunnel,
          });
        } else {
          Tracker['Complete Login'](payload.provider as Provider);
        }
        Sentry.setUser({
          email: this.user.email,
          id: this.user.userId,
          username: this.user.nickName,
        });

        // TODO: DATADOG 모니터링 사용자 세션 정보 설정(도입 여부에 따라 유지 또는 삭제 결정)
        datadogRum.setUser({
          id: String(this.user.userId),
          email: this.user.email,
          name: this.user.userName,
          nickName: this.user.nickName,
        });

        Broadcast.postMessage('login');
      }
    },
    async migrationSocial(payload) {
      try {
        const { data } = await userAuthService.migrationSocial({
          onetimeToken: payload.data.response.onetimeToken,
          email: payload.data.response.email,
        });
        await this.handleLoginResponse(data, payload);
      } catch (error) {
        this.handleLoginError(error);
      }
    },
    handlePhoneDuplication(data) {
      // 휴대폰 번호 중복 처리 로직
      // 예: 사용자에게 메시지 표시
      // handlePhoneDuplication
      StorageItem.local('phoneDuplication').set(data.data.response);
    },

    async fetchUserInfo(socialLoginType?: SocialLoginType) {
      const { data } = await userAuthService.session();
      this.user = {
        ...data.data.items,
        lastLoginType: socialLoginType ?? 'email',
      };
      return data;
    },

    setSocialHistory(payload: any) {
      this.socialHistory = payload;
    },

    setOldInfo(payload: null | OldUserInfo) {
      this.oldInfo = payload;
    },

    async logout(errorCode?: number) {
      console.log('logout :>> ', errorCode);
      try {
        this.$reset();

        // Next 쪽 로그인 해제를 위한 쿠키 제거
        await $fetch('/nuxt-api/clear-next-auth-cookies', { method: 'POST' });
        await $fetch('/nuxt-api/set-weolbu-at', { method: 'POST', body: { token: null } });

        localStorage.removeItem('user-auth');
        Broadcast.postMessage('logout');

        // TODO: DATADOG 모니터링 사용자 세션 정보 설정(도입 여부에 따라 유지 또는 삭제 결정)
        datadogRum.clearUser();

        if (errorCode !== 419 && Notification.permission === 'granted') {
          const token = await requestPermission();
          if (token) {
            await mainService
              .deleteUserDeviceToken({
                deviceToken: token,
              })
              .then((e) => {
                console.log('error', e);
              });
          }
        }
      } catch (e) {
        console.log('error', e);
      }
    },
    setUserNickname(userName: string, nickName: string | null): string {
      if (!nickName || nickName === '') {
        return userName;
      }
      return nickName;
    },
    // 비밀번호 변경일 갱신
    async renewPasswordDate() {
      try {
        await userAuthService.renewPasswordDate();
      } catch (error) {
        this.handleLoginError(error);
      }
    },
    handleLoginError(error) {
      // 에러 케이스
      // "apiVersion": null,
      // "status": {
      //   "code": 200,
      //   "message": "OK",
      //   "errorCode": "ERROR_XXXX",
      //   "errorMessage": "에러메시지"
      // },
      Sentry.setUser({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
      });
      console.error(error.errorMessage);
    },
    processModalOpen(data) {
      // 관심사 신규 정책
      // signUpPath 값에 따라 분기 처리
      // 1. ETC, BENEPIA 인경우 관심사 모달을 호출
      // 2. CLASS 인경우 신규 클래스 모달을 호출
      // 3. COMMUNIT 인경우 기존 탈리 모달을 노출
      if (data.data.isSignUp) {
        const redirectUri = StorageItem.local(REDIRECT_URI).get() || '/';
        const { path } = getPathResource(redirectUri);

        const couponStore = useCouponStore();
        if (path === 'CLASS') {
          couponStore.setClassWelcomePopup(true);
          return;
        }
        if (path === 'COMMUNITY') {
          couponStore.setWelcomePopup(true);
          return;
        }
        const interestStore = useInterestStore();
        interestStore.setInterestPopup(true);
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return Boolean(state.user && state.token?.accessToken);
    },
  },
});
