<script setup lang="ts">
import { REFERRAL_STORAGE_KEY } from '~/composables/useUserInviteCode';

import Tracker from '~/libs/Tracker';
import { delay } from '~/libs/misc';

import { useBasicsStore } from '~/services/base';
import { useCommonStore } from '~/services/common';

const router = useRouter();
const route = useRoute();
const basicStore = useBasicsStore();
const commonStore = useCommonStore();

const pcSns = ref(null);

const referralCode = computed(() => commonStore.shareWithInviteCodeParams?.referralCode);

const handleMobileVisible = computed<boolean>({
  get: () => {
    return basicStore.shareMobileOpen;
  },
  set: (val) => {
    basicStore.setShareMobileOpen(val);
  },
});

const share = (type: 'kakao' | 'clipboard') => {
  Tracker['Click Share Button']({ type, url: window.location.href, path: route.path });

  // 여기는 사용하지 않는 컴포넌트로 보임
  if (referralCode.value) {
    Tracker['Click Creator Share Button']({ type, url: window.location.href, path: route.path });

    router.replace({
      query: {
        ...route.query,
      },
    });

    delay(125).then(() => {
      $share(type);
      router.replace({
        query: {
          ...route.query,
          inviteCode: undefined,
        },
      });
      sessionStorage.removeItem(REFERRAL_STORAGE_KEY);
      commonStore.shareWithInviteCodeParams = {} as any;
    });
  } else {
    $share(type);
  }
};

const onCloseSnsModal = () => {
  handleMobileVisible.value = false;
  if (pcSns.value) {
    pcSns.value.hide();
  }
};

router.afterEach((to, from, failure) => {
  onCloseSnsModal(); // 이동시 항상 sns modal 닫음.
});

defineExpose({
  pcSns,
});
</script>

<template>
  <Dialog v-model:visible="handleMobileVisible" modal class="wb-dialog wb-dialog--bottom">
    <div class="wb-dialog__close">
      <Button icon="pi pi-times" text rounded @click="onCloseSnsModal" />
    </div>
    <div class="wb-dialog-sns">
      <div class="wb-dialog-sns__title">공유하기</div>
      <div class="wb-dialog-sns__list">
        <NuxtLink class="wb-dialog-sns__item" style="cursor: pointer" @click="share('kakao')">
          <strong class="wb-dialog-sns__item-kakao">
            <i class="pi pi-kakao"></i>
          </strong>
          카카오
        </NuxtLink>
        <NuxtLink class="wb-dialog-sns__item" style="cursor: pointer" @click="share('clipboard')">
          <strong class="wb-dialog-sns__item-link">
            <i class="pi pi-link"></i>
          </strong>
          주소복사
        </NuxtLink>
      </div>
    </div>
  </Dialog>
  <Menu ref="pcSns" :popup="true" class="wb-product-view__button-layer">
    <template #end>
      <Button
        icon="pi pi-kakao"
        rounded
        aria-label="Filter"
        class="wb-product-view__button-kakao"
        @click="share('kakao')" />
      <Button
        icon="pi pi-link"
        rounded
        aria-label="Filter"
        class="wb-product-view__button-link"
        @click="share('clipboard')" />
    </template>
  </Menu>
</template>

<style lang="scss"></style>
