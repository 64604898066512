import * as Sentry from '@sentry/vue';

import type Tracker from '~/libs/Tracker';

import BaseService from '~/services/BaseService';

type WbAnalyticsLogEvent = 'sign-up' | 'click-product-purchase-button';
type WbAnalyticsLogPayload<T extends keyof typeof Tracker> = {
  eventType: T;
  eventProperties: any;
  [key: string]: unknown;
};

class WbAnalytics extends BaseService {
  constructor() {
    super('/users/event-logs');
  }

  async logEvent<T extends keyof typeof Tracker>(path: WbAnalyticsLogEvent, payload: WbAnalyticsLogPayload<T>) {
    try {
      await this.postV2(`/${path}`, { useKotlinBaseUrl: true, body: payload });
    } catch (e) {
      console.error(`${path} : logging 실패`);
      Sentry.captureException(`${path} : logging 실패`, { data: payload });
    }
  }
}

export const wbAnalyticsService = new WbAnalytics();
